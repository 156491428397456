* {
  box-sizing: border-box;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body, html, #root {
  height: 100%;
}
